import { handleSignout } from "actions";
import { handleUpdateDetail } from "actions";
import { message } from "antd";
import { routes } from "App";
import { isEqual } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AppUsers from "services/apppUsers";
import { setDefaultHeaders } from "services/request";

const UserContext = createContext(null);

export function userAvatar(user) {
  return user?.userAvatarrrrrr || "assets/images/person01.png";
}

export function userFullName(user) {
  return (
    user?.companyName ||
    [user?.firstName, user?.lastName].filter((s) => s).join(" ") ||
    user?.username
  );
}

export function userLevel(level) {
  switch (level) {
    case 1:
      return <FormattedMessage id="member" />;
    case 2:
      return <FormattedMessage id="family" />;
    case 3:
      return <FormattedMessage id="company" />;
    case 4:
      return <FormattedMessage id="enterprise" />;
    // eslint-disable-next-line no-fallthrough
    case 5:
      return <FormattedMessage id="organization_2" />;
    // eslint-disable-next-line no-fallthrough
    default:
      return <FormattedMessage id="new_member" />;
  }
}

export function userPower(level) {
  switch (level) {
    case 1:
      return 0;
    case 2:
      return 5;
    case 3:
      return 10;
    case 4:
      return 15;
    case 5:
      return 20;
    default:
      return 0;
  }
}

export function userRole(user, currentUser) {
  const { appUserId } = currentUser || {};
  if (user?.memberReferIdF1 === appUserId) {
    return "F1";
  } else if (user?.memberReferIdF2 === appUserId) {
    return "F2";
  } else if (user?.memberReferIdF3 === appUserId) {
    return "F3";
  } else if (user?.memberRefIdF4 === appUserId) {
    return "F4";
  } else if (user?.memberReferIdF5 === appUserId) {
    return "F5";
  }
  return "F1";
}

export function isVerified(user) {
  return user?.isVerified === 1;
}

export function canWithdraw(user) {
  return isVerified(user) && hasSecondaryPassword;
}

export function hasSecondaryPassword(user) {
  return user?.secondaryPassword;
}

export function UserProvider({ children }) {
  const user = useSelector((state) => state.member.data);
  const userPhoneNumber = useMemo(() => user?.phone_number, [user?.phone_number]) ;
  const dispatch = useDispatch();

  const updateUser = useCallback(
    (data) => {
      return AppUsers.updateInfoUser({ id: user.id, data }).then(() => {
        refresh();
      });
    },
    [dispatch, user?.id]
  );

  const refresh = useCallback(
    function refresh() {
      if (userPhoneNumber) {
        return AppUsers.getDetailUserById({
          phone_number: userPhoneNumber,
        }).then((result) => {
          const { isSuccess, data } = result;
          if (isSuccess) {
            dispatch(
              handleUpdateDetail({
                ...data,
                lastActive: new Date().toISOString(),
                isUsing: true,
              })
            );
          }
        });
      }
    },
    [dispatch, userPhoneNumber]
  );

  function registerStaking(
    monney_loan,
    monney_mustpay,
    time_loan,
    moreData = {}
  ) {
    return AppUsers.updateInfoUser(
      {
        id: user?.id,
        data: {
          monney_loan,
          monney_mustpay: Number(monney_mustpay),
          time_loan,
          ...moreData,
        },
      },
      true
    ).then((response) => {
      if (response?.data) {
        message.success("Đăng ký vay thành công!");
      } else {
        message.error("Đăng ký vay thất bại!");
      }
      return refresh();
    });
  }

  function signOut() {
    dispatch(handleSignout(user.id));
    setTimeout(() => {
      window.location.href = routes.login.path;
    }, 200);
  }

  // Auto refresh
  const autoRefresh = () => {
    if (user && user.isUserLoggedIn) {
    AppUsers.getDetailUserById({
      phone_number: userPhoneNumber,
    }).then((result) => {
      const { isSuccess, data } = result;
      if (isSuccess && !isEqual(data, user)) {
        dispatch(handleUpdateDetail(data));
      }
      setTimeout(autoRefresh, 5000);
    });
  }
  };

  useEffect(() => {
    if (user) {
      setDefaultHeaders({ authorization: `Bearer ${user.token}` });
      refresh();
      autoRefresh()
      const handleUnload = () => {
        dispatch(handleUpdateDetail({ isUsing: false, lastActive: new Date().toISOString(), }));
      };
      window.addEventListener("beforeunload", handleUnload);
      return () => {
        window.removeEventListener("beforeunload", handleUnload);
      };
    }
  }, [userPhoneNumber]);


  return (
    <UserContext.Provider
      value={{ user, updateUser, refresh, signOut, registerStaking }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
