import React from 'react'

export const FETCH_APPS_REQUEST = "FETCH_APPS_REQUEST";
export const FETCH_APPS_SUCCESS = "FETCH_APPS_SUCCESS";
export const FETCH_APP_CHANGE = "FETCH_APP_CHANGE";
export const FETCH_APP_CONFIGURATION_REQUEST =
  "FETCH_APP_CONFIGURATION_REQUEST";
export const FETCH_APP_CONFIGURATION_SUCCESS =
  "FETCH_APP_CONFIGURATION_SUCCESS";
export const INIT_FINISH = "INIT_FINISH";

export const STATUS_CAN_DRAW = [40, 50];

export const STATUS_LOAN = {
  10: { step: 0, status: "Chờ xác nhận" },
  20: { step: 1, status: "Xác minh thông tin thành công", color: "green" },
  21: { step: 0, status: "Xác minh thông tin thất bại", color: "red" },
  30: {
    step: 2,
    status: "Xét duyệt thành công",
    color: "green",
    hideDraw: true,
    hideMoney: true,
  },
  40: {
    step: 2,
    status: "Có thể rút tiền về tài khoản chính",
    color: "blue",
    hideDraw: true,
  },
  50: {
    step: 2,
    status: "Xác minh vốn thành công, có thể rút tiền về",
    color: "blue",
  },
  51: { step: 3, status: "Đang xử lý về tài khoản đăng ký" },
  52: {
    step: 3,
    status:
      "Lệnh rút tiền thất bại. Hệ thống báo lỗi tài khoản nhận tiền. Vui lòng kiểm tra lại thông tin",
    color: "red",
  },
  60: { step: 3, status: "Giao dịch thất bại", color: "red" },
  70: {
    step: 4,
    status: "Chúc mừng bạn đã nhận tiền thành công",
    color: "green",
  },
};

export const STATUS_WALLET = {
  10: { status: "Chờ xác nhận", hideMoney: true },
  20: { status: "Xác minh thông tin thành công", className: "success", hideMoney: true },
  21: { status: "Xác minh thông tin thất bại", className: "error", hideMoney: true },
  30: { status: "", hideMoney: true, hideDraw: true },
  40: { status: "", hideDraw: true },
  50: {
    status: "Rút tiền",
    canDraw: true,
  },
  51: { status: "Chờ xác nhận", className: "warning" },
  52: {
    status: "Xác minh",
    className: "error",
  },
  60: { status: "Giao dịch thất bại", className: "error" },
  70: {
    status: "Nhận tiền thành công",
    className: "success",
  },
};

export const HOME_BTN_LIST = [
  {
    label: 'VAY MUA ĐIỆN MÁY',
    icon:(
      <div className="d-flex align-items-center justify-content-center">
        <img src={'/assets/images/card.png'} style={{width:'80px'}}/>
      </div>
    ),
  },
  {
    label: 'MUA BẢO HIỂM',
    icon:(
      <div className="d-flex align-items-center justify-content-center">
        <img src={'/assets/images/pay-card.png'} style={{width:'80px'}}/>
      </div>
    ),
  },
  {
    label: 'VAY MUA XE',
    icon:(
      <div className="d-flex align-items-center justify-content-center">
        <img src={'/assets/images/home_btn3.jpg'} style={{width:'80px'}}/>
      </div>
    ),
  },
  {
    label: 'VAY THẺ TÍN DỤNG',
    icon:(
      <div className="d-flex align-items-center justify-content-center">
        <img src={'/assets/images/bag.png'} style={{width:'80px'}}/>
      </div>
    ),
  },
  {
    label: 'VAY TIẾT KIỆM',
    icon:(
      <div className="d-flex align-items-center justify-content-center">
        <img src={'/assets/images/save-money.png'} style={{width:'80px'}}/>
      </div>
    ),
  },
  {
    label: 'VAY TIỀN MẶT',
    icon:(
      <div className="d-flex align-items-center justify-content-center">
        <img src={'/assets/images/account.png'} style={{width:'80px'}}/>
      </div>
    ),
  },
]
export const LOAN_METHOD = {
  CASH: "Vay món tiền mặt",
  LIMIT:"Vay hạn mức tiền mặt",
  INSTALLMENT:"Vay trả góp",
  BUSINESS:"Vay doanh nghiệp"
}

export const CONTACT_METHOD = {
  ALL: "Nhận cuộc gọi bất cứ lúc nào",
  MORNING:"Nhận cuộc gọi vào buổi sáng",
  EVENING:"Nhận cuộc gọi vào buổi tối"
}