
import i18next from 'i18next'
import { messages_en, messages_vi,messages_th,messages_in } from './index'
import { initReactI18next } from 'react-i18next'

const messages = {
  en: messages_en,
  vi: messages_vi,
  th: messages_th,
  in: messages_in
}

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use.
  resources: {
    vi: {
      translation: messages.vi
    },
    en: {
      translation: messages.en // 'common' is our custom namespace
    },
    th: {
      translation: messages.th // 'common' is our custom namespace
    },
    in: {
      translation: messages.in // 'common' is our custom namespace
    }
  },
  debug: process.env.NODE_ENV !== 'production',
  fallbackLng: 'en'
})

export default i18next
