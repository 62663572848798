import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './App';
import 'antd/dist/antd.css';
import './styles/main.scss';
import './App.css';
import './styles/_antd.scss';
import { I18nextProvider } from 'react-i18next'
import i18next from './translations/i18next'
import store from './store'
import { IntlProviderWrapper } from 'helper/Internationalization';
import { ModalProvider } from 'context/ModalContext';
import { SystemProvider } from 'context/SystemContext';
import { UserProvider } from 'context/UserContext';

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
  <Provider store={store}>
    <IntlProviderWrapper>
      <SystemProvider>
        <UserProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
        </UserProvider>
      </SystemProvider>
    </IntlProviderWrapper>
  </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

