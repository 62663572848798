import ScreenLockAccount from "components/Layout/ScreenLockAccount";
import MenuNavigation from "components/MenuNavigation";
import { useUser } from "context/UserContext";
import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import "./index.scss";

function LayoutPage(props) {
  const { Component, className = "" } = props;
  const { user } = useUser();
  const { location, history } = props;

  const handleRedirect = useCallback(
    (path) => {
      history.push(path);
    },
    [history]
  );

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      const headerId = document.getElementById("header-sticky");
      if (headerId && headerId.classList) {
        if (window.pageYOffset > 0) {
          headerId.classList.toggle("sticky");
        } else {
          headerId.classList.remove("sticky");
        }
      }
    });
  }, []);

  useEffect(() => {
    const bodyId = document.getElementById("body-root");
    if (bodyId) {
      bodyId.className = "bg-gray";
      bodyId.classList.add(className || "");
    }
  }, [className]);

  const isLocked = user?.active === 0;

  return (
    <>
      {isLocked ? <ScreenLockAccount /> : <Component {...props} />}

      {/* <MenuNavigation location={location} onRedirect={handleRedirect} /> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  member: state.member || {},
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage);
