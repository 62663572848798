import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleInit } from "actions/appAction";
import "./index.scss";
import { Button } from "antd";
import { ArrowRightOutlined, RightOutlined } from "@ant-design/icons";

export default function WelcomePage({setIsSplish}) {
  const [splish, setSplish] = useState(false);
  const [countTimer, setCountTimer] = useState();
  const [countNumber, setCountNumber] = useState();
  const dispatch = useDispatch();
  const timer = function () {
    if (countNumber >= 0) {
      setCountNumber(countTimer - 1);
    } else {
      dispatch(handleInit());
      clearInterval(countTimer);
    }
    setCountNumber(countTimer - 1);
  };

  useEffect(() => {
    const intervalId = setInterval(timer, 3000);
    setCountTimer(intervalId);
    return () => {
      clearInterval(countTimer);
    };
  }, []);

  return (
    <div className="init-page">
    {!splish ? (
      <>
        <div className="text-center splash">
          <img className="splash-img" src={"/Splash1.jpg"}  alt="initImg" />
          <Button className="next-btn" onClick={()=>setSplish(true)}><RightOutlined /></Button>
        </div>
      </>
    ):(
      <>
        <div className="text-center splash">
          <img className="splash-img" src={"/Splash2.jpg"}  alt="initImg" />
          <Button className="next-btn" onClick={()=>setIsSplish(false)}><RightOutlined /></Button>
        </div>
      </>
    )
    }
      
      
    </div>
  );
}
